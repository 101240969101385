import React from 'react';
import Particles from 'react-particles-js';

const HeaderParticles = () => (
  <Particles
    className={'particles-header'}
    params={{
      particles: {
        number: {
          value: 15
        },
        move: {
          speed: 10
        }
      }
    }}
  />
);

export default HeaderParticles;
