import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Parallax = props => {
  const {
    id,
    layers,
    layersImages,
    layersContainerStyling,
    layersStyling
  } = props;

  return layers.map((layer, index) => {
    const ParallaxLayer = styled.div`
      background: url(${layersImages[index]});
    `;

    return (
      <ParallaxLayer
        className="parallax-layer"
        id={`${id}Layer${index}`}
        key={index}
        style={{
          ...{
            height: window.innerHeight * 1.2
          },
          ...styles.layerContainerStyling,
          ...styles[`layer${index}`],
          ...layersContainerStyling[index],
          ...layersStyling[index]
        }}
      >
        {layer && layer.content()}
      </ParallaxLayer>
    );
  });
};

Parallax.displayName = 'Portfolio.Parallax';

Parallax.propTypes = {
  id: PropTypes.string,
  layers: PropTypes.array,
  layersImages: PropTypes.array,
  layersContainerStyling: PropTypes.array,
  layersStyling: PropTypes.array
};

Parallax.defaultProps = {
  id: '',
  layers: [],
  layersImages: [],
  layersContainerStyling: [],
  layersStyling: []
};

const styles = {
  layerContainerStyling: {
    width: '100%',
    transition: '3s'
  },
  layer0: {
    position: 'relative',
    zIndex: 98,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  layer1: {
    position: 'absolute',
    top: '15%',
    zIndex: 99,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat'
  },
  layer2: {
    position: 'absolute',
    top: '50%',
    zIndex: 99,
    webkitTransitionDelay: '0.5s' /* Safari */,
    transitionDelay: '0.5s'
  }
};

export default Parallax;
