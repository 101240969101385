import React from 'react';
import Parallax from '../../components/common/parallax/Parallax';
import Button3D from '../../components/common/button-3d/Button3D';
import TextualContent from '../../i18n/TextualContent';

import servicesParallaxImage from '../../assets/images/parallax1/parallax10.jpg';

const ServicesParallax = () => {
  return (
    <Parallax
      id="parallax1"
      layers={[
        null,
        {
          content: () => {
            return (
              <div>
                <h1 style={TextualContent.servicesParallaxHeader.style}>
                  {TextualContent.servicesParallaxHeader.text}
                </h1>
                <p style={TextualContent.servicesParallaxContent.style}>
                  {TextualContent.servicesParallaxContent.text}
                </p>
                <div id="parallax1Img" style={styles.parallax1Img}>
                  <Button3D buttonStyle={styles.parallax1Button}>
                    <a
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        fontSize: 'inherit',
                        fontFamily: 'inherit',
                        fontWeight: 'inherit'
                      }}
                      href={'mailto:a.samyabdelhay@gmail.com'}
                    >
                      {TextualContent.servicesParallaxHireMe.text}
                    </a>
                  </Button3D>
                </div>
              </div>
            );
          }
        }
      ]}
      layersImages={[servicesParallaxImage]}
      layersContainerStyling={[
        null,
        {
          height: '80%',
          width: '55%',
          left: '15%'
        }
      ]}
      layersStyling={[
        null,
        {
          top: '20%'
        }
      ]}
    />
  );
};

const styles = {
  parallax1Img: {
    textAlign: 'center',
    padding: 50,
    backgroundImage: 'url(../../../assets/images/parallax1/parallax11.png)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  parallax1Button: {
    width: 'auto',
    height: 'auto',
    padding: '5px 30px',
    margin: '0 auto'
  }
};

export default ServicesParallax;
