import React from 'react';
import { Col } from 'react-bootstrap';

const SectionBody = props => {
  const { bodyLayout, colStyle, sectionBody } = props;

  return sectionBody.map((sectionBodyElement, index) => {
    return (
      <Col style={colStyle} key={index} lg={bodyLayout[index]}>
        {sectionBodyElement()}
      </Col>
    );
  });
};

export default SectionBody;
