import React from 'react';

const Overlay = props => {
  const { overlayStyle, hovered } = props;
  return (
    <div
      style={{
        ...styles.overlay,
        ...overlayStyle,
        ...{
          transform: hovered ? 'scale(1,1)' : 'scale(0,0)'
        }
      }}
    />
  );
};

const styles = {
  overlay: {
    position: 'absolute',
    top: 0,
    zIndex: 0,
    backgroundColor: '#272727',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '200px',
    transition: '0.5s'
  }
};

export default Overlay;
