import React from 'react';
import App from '../app-container/App';
import AppLoading from './app-loading/AppLoading';

class Setup extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      loaded: false // Indicates whether the component is hovered or not
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 2500);
  }

  render() {
    if (!this.state.loaded) return <AppLoading />;
    return <App />;
  }
}

export default Setup;
