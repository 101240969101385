import React from "react";
import SectionContent from "../../components/common/section-content/SectionContent";
import InViewWrapper from "../../components/common/in-view-wrapper/InViewWrapper";
import Animetable from "../../components/common/animetable/Animetable";
import TextualContent from "../../i18n/TextualContent";
import PortfolioBox from "./portfolio-box/PortfolioBox";
import FiltersMenu from "./filters-menu/FiltersMenu";
import PortfolioProjectsData from "./PortfolioProjectsData";

class Portfolio extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      filterSelected: "ALL" // Selection indicators array
    };
  }

  renderPortfolioItems() {
    const items = PortfolioProjectsData.map((item, index) => {
      return () => {
        return (
          <InViewWrapper key={index}>
            {({ isInView }) => {
              if (!isInView) return null;
              return (
                <div
                  id={`portfolioBox${index}`}
                  style={{
                    ...styles.portfolioItemWrapper,
                    ...{
                      transform:
                        this.state.filterSelected === "ALL" ||
                        this.state.filterSelected === item.platform
                          ? "scale(1, 1)"
                          : "scale(0, 0)"
                    }
                  }}
                >
                  <Animetable
                    animationKeyframes={`
                        0% {
                          transform: scale(0, 0);
                        }
                        100% {
                          transform: scale(1, 1);
                        }
                      `}
                    animationProps={"2s"}
                  >
                    <Animetable
                      animationKeyframes={`
                          0% {
                            transform: rotate(0deg);
                          }
                          100% {
                            transform: rotate(360deg);
                          }
                        `}
                      animationProps={"0.1s 20"}
                    >
                      <PortfolioBox
                        image={item.thumb}
                        name={item.name}
                        repoLink={item.repoLink}
                        link={item.link}
                        android={item.android}
                        ios={item.ios}
                      />
                    </Animetable>
                  </Animetable>
                </div>
              );
            }}
          </InViewWrapper>
        );
      };
    });
    return items;
  }

  render() {
    return (
      <div>
        <SectionContent
          sectionHeader={{
            content: [
              TextualContent.portfolioHeader.text,
              TextualContent.portfolioUnderHeader1.text,
              TextualContent.portfolioUnderHeader2.text
            ],
            styles: [
              TextualContent.portfolioHeader.style,
              TextualContent.portfolioUnderHeader1.style,
              TextualContent.portfolioUnderHeader2.style
            ],
            wrapperStyle: { marginBottom: 40 }
          }}
          sectionBody={[
            () => {
              return (
                <FiltersMenu
                  filters={["ALL", "REACTJS", "REACT NATIVE/EXPO"]}
                  onFilterSelected={filterSelected =>
                    this.setState({ filterSelected: filterSelected })
                  }
                />
              );
            }
          ]}
        />
        <SectionContent
          bodyLayout={[4, 4, 4]}
          sectionBody={this.renderPortfolioItems()}
        />
      </div>
    );
  }
}

const styles = {
  portfolioItemWrapper: {
    width: "100%",
    transition: "1s",
    display: "inline-block",
    margin: "15px 0"
  }
};

export default Portfolio;
