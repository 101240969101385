import React from 'react';

const AppNavbarController = WrappedComponent => {
  return class AppNavbarController extends React.Component {
    constructor(props) {
      super();

      // Component level state variables
      this.state = {
        currentSection: 'home', // Indicates the section that is currently displayed on the page
        sticky: false // Indicates whether the navbar is sticky or not
      };
    }

    /**
     * Excuted after component rendering
     */
    componentDidMount() {
      // Attach scroll listener
      document.addEventListener('scroll', this.trackScrolling);
      this.trackScrolling();
    }

    /**
     * Excuted after component is unmounted
     */
    componentWillUnmount() {
      // Detaching scroll listener
      document.removeEventListener('scroll', this.trackScrolling);
    }

    /**
     * Responsible for handling page scrolling event
     */
    trackScrolling = () => {
      // Detect which section is currently being displayed after scrolling
      let currentSection = this.getCurrentSection();
      if (this.state.currentSection !== currentSection)
        this.setState({ currentSection: currentSection });

      // Track navbar sticky state
      let isSticky =
        document.getElementById('header').getBoundingClientRect().bottom <= 0;
      if (this.state.sticky !== isSticky) this.setState({ sticky: isSticky });
    };

    /**
     * Responsible for detecting the currently displayed section upon scrolling
     */
    getCurrentSection() {
      // Sections to be tracked
      let sections = [
        'header',
        'aboutParallax',
        'servicesParallax',
        'portfolio',
        'contact'
      ];
      for (var i = 0; i < sections.length; i++) {
        // Get each section bottom
        let sectionBottom = document
          .getElementById(sections[i])
          .getBoundingClientRect().bottom;
        // Check if section's bottom is not reached yet, then it is the current section
        if (sectionBottom > 100) return sections[i];
      }
      return 'footera fter contact';
    }

    render() {
      const newProps = {
        currentSection: this.state.currentSection,
        sticky: this.state.sticky
      };
      return <WrappedComponent {...this.props} {...newProps} />;
    }
  };
};

export default AppNavbarController;
