import React from "react";
import SectionContent from "../../components/common/section-content/SectionContent";
import CustomButton from "../../components/common/custom-button/CustomButton";
import TextualContent from "../../i18n/TextualContent";
import aboutImage from "../../assets/images/about-section/about-image.png";
import styled from "styled-components";

const PhotoFrame = styled.div`
  position: relative;
  z-index: 1;
  top: 0;
  border: 10px solid #fff;
  width: 100%;
  height: 100%;
  background: url(${aboutImage});
  background-color: #000;
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
`;

const About = () => (
  <SectionContent
    justifyContentRow
    bodyLayout={[3, 8]}
    sectionHeader={{
      content: [
        TextualContent.aboutHeader.text,
        TextualContent.aboutUnderHeader.text
      ],
      styles: [
        TextualContent.aboutHeader.style,
        TextualContent.aboutUnderHeader.style
      ],
      wrapperStyle: { marginBottom: 80 }
    }}
    sectionBody={[
      () => {
        return <PhotoFrame id="photoFrame" />;
      },
      () => {
        return (
          <div>
            <div
              id="aboutContent"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <h4 style={TextualContent.aboutContentName.style}>
                {TextualContent.aboutContentName.text}
              </h4>
              <h3 style={TextualContent.aboutContentJob.style}>
                {TextualContent.aboutContentJob.text}
              </h3>
              <p style={TextualContent.aboutContentBody1.style}>
                {TextualContent.aboutContentBody1.text}
              </p>
              <p style={TextualContent.aboutContentBody2.style}>
                {TextualContent.aboutContentBody2.text}
              </p>
            </div>
            <CustomButton id="aboutButton">
              <a
                style={styles.buttonLink}
                href={
                  "https://drive.google.com/file/d/1OKGrvy8YziSnOuryvR9S91wuS9hBEq5Z/view?usp=sharing"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                DOWNLOAD CV
              </a>
            </CustomButton>
          </div>
        );
      }
    ]}
  />
);

const styles = {
  buttonLink: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit"
  }
};

export default About;
