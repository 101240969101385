import React from "react";
import { Grid, Row, Col, ButtonToolbar } from "react-bootstrap";
import CustomButton from "../../../../common/custom-button/CustomButton";
import Animetable from "../../../../common/animetable/Animetable";
import TextualContent from "../../../../../i18n/TextualContent";

const HeaderLeft = () => (
  <div id="headerLeft">
    <Grid>
      <Row>
        <Col lg={6}>
          <Animetable
            animationKeyframes={`
              from {opacity: 0;}
              to {opacity: 1;}
              from {margin-top: -25rem;}
              to {margin-top: 0px;}
            `}
            animationProps="2s"
          >
            <h4 style={TextualContent.headerHiIam.style}>
              {TextualContent.headerHiIam.text}
            </h4>

            <h1 style={TextualContent.headerName.style}>
              {TextualContent.headerName.text}
            </h1>
          </Animetable>

          <Animetable
            animationKeyframes={`
              from {margin-top: 25rem;}
              to {margin-top: 0;}
              from {margin-bottom: 25rem;}
              to {margin-bottom: 0;}
            `}
            animationProps="2s"
          >
            <h3 style={TextualContent.headerJob.style}>
              {TextualContent.headerJob.text}
            </h3>
          </Animetable>

          <Animetable
            animationKeyframes={`
              from {opacity: 1;}
              to {opacity: 1;}
            `}
            animationProps="2s"
          >
            <ButtonToolbar>
              <CustomButton
                style={styles.hireMeButton}
                hoverAnimation={styles.hireMeButtonHover}
              >
                <a
                  style={TextualContent.headerHireMe.style}
                  href={"mailto:a.samyabdelhay@gmail.com"}
                >
                  {TextualContent.headerHireMe.text}
                </a>
              </CustomButton>
              <CustomButton
                style={{ float: "left" }}
                hoverAnimation={styles.downloadCVButtonHover}
              >
                <a
                  style={TextualContent.headerDownloadCv.style}
                  href={
                    "https://drive.google.com/file/d/1OKGrvy8YziSnOuryvR9S91wuS9hBEq5Z/view?usp=sharing"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {TextualContent.headerDownloadCv.text}
                </a>
              </CustomButton>
            </ButtonToolbar>
          </Animetable>
        </Col>
        <Col lg={7} />
      </Row>
    </Grid>
  </div>
);

const styles = {
  hireMeButton: {
    margin: "0 20px 0 0",
    backgroundColor: "#fff",
    color: "#000",
    float: "left"
  },
  hireMeButtonHover: {
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.4)"
  },
  downloadCVButtonHover: {
    color: "#000",
    backgroundColor: "#fff"
  }
};

export default HeaderLeft;
