import React from 'react';
import Animetable from '../../../../common/animetable/Animetable';

const MyHead = () => (
  <Animetable
    id="headerRightMe"
    animationKeyframes={`
        0% {bottom: 100%; margin-top: -200%;}
        90% {bottom: 100%; margin-top: -200%;}
        100% {bottom: -10%; margin-top: 0;}
      `}
    animationProps="2.5s"
  >
    <div />
  </Animetable>
);

export default MyHead;
