import React from 'react';
import SectionContent from '../../components/common/section-content/SectionContent';
import InViewWrapper from '../../components/common/in-view-wrapper/InViewWrapper';
import Animetable from '../../components/common/animetable/Animetable';
import TextualContent from '../../i18n/TextualContent';
import TestimonalsBox from './testimonals-box/TestimonalsBox';
import ContactCard from './contact-card/ContactCard';
import testThumb from '../../assets/images/contact-section/test1.png';

class Contact extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      filterSelected: 'ALL' // Selection indicators array
    };
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <SectionContent
          bodyLayout={[1, 10, 1]}
          sectionHeader={{
            content: [TextualContent.contactHeader.text],
            styles: [TextualContent.contactHeader.style]
          }}
          sectionBody={[
            () => {},
            () => {
              return (
                <TestimonalsBox
                  testimonal={{
                    thumb: testThumb,
                    content: TextualContent.testimonal
                  }}
                />
              );
            }
          ]}
        />
        <InViewWrapper>
          {({ isInView }) => {
            if (!isInView) return null;
            return (
              <Animetable
                id="contactCard"
                style={styles.animatedBox}
                animationKeyframes={`
                  from {opacity: 0;}
                  top {opacity: 1;}
                  from {top: 50%}
                  to {top: 95%}
              `}
                animationProps={'2s'}
              >
                <ContactCard />
              </Animetable>
            );
          }}
        </InViewWrapper>
      </div>
    );
  }
}

const styles = {
  animatedBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
    top: '95%'
  }
};

export default Contact;
