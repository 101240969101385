import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl } from 'react-bootstrap';

class InputBox extends React.Component {
  constructor(props) {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.forwardToForm(e.target.value);
  }

  render() {
    const { label, inputArea, inputStyle } = this.props;
    return (
      <FormGroup controlId={label}>
        <FormControl
          onChange={this.handleChange}
          componentClass={inputArea ? 'textArea' : 'input'}
          style={{
            ...styles.input,
            ...(this.props.error ? { border: '1px solid #9b2222' } : {}),
            ...(inputArea ? { height: 100, paddingTop: 20 } : {}),
            ...inputStyle
          }}
          placeholder={label}
        />
      </FormGroup>
    );
  }
}

const styles = {
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #3a3939',
    borderRadius: 25,
    height: 50,
    resize: 'none',
    padding: '0px 30px',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
    color: 'rgb(208, 208, 208)'
  }
};

InputBox.displayName = 'Portfolio.InputBox';

InputBox.propTypes = {
  label: PropTypes.string,
  inputStyle: PropTypes.object
};

InputBox.defaultProps = {
  label: 'Input',
  inputArea: false,
  inputStyle: {}
};

export default InputBox;
