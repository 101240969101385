import React from 'react';
import Section from '../../common/section/Section';
import Footer from '../../../sections/footer/Footer';

const AppFooter = () => (
  <Section parallax>
    <Footer />
  </Section>
);

export default AppFooter;
