import React from 'react';
import AppNavbar from './app-navbar/AppNavbar';
import Header from './header/Header';

const AppHeader = () => (
  <div>
    <Header visible={true} />
    <AppNavbar />
  </div>
);

export default AppHeader;
