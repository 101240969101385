import React from 'react';
import PropTypes from 'prop-types';

const Card = props => {
  const { id, className, children, cardContainerStyle } = props;
  return (
    <div
      style={{
        ...styles.cardContainerStyle,
        ...cardContainerStyle
      }}
      id={id}
      className={className}
    >
      {children}
    </div>
  );
};

Card.displayName = 'Portfolio.Card';

Card.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  cardContainerStyle: PropTypes.object,
  children: PropTypes.node
};

Card.defaultProps = {
  id: '',
  className: '',
  cardContainerStyle: {},
  children: {}
};

const styles = {
  cardContainerStyle: {
    width: '100%',
    height: '300px',
    padding: '30px 0',
    backgroundColor: '#202020',
    borderRadius: 6,
    position: 'relative',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)'
  }
};

export default Card;
