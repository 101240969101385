import React from 'react';

class ProgressBar extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      beginAnimation: false // Indicates whether progress animation started or not
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ beginAnimation: true });
    }, 200);
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <div style={styles.progressWrapper}>
          <div
            id={this.props.id}
            style={{
              ...styles.progress,
              ...{
                width: this.state.beginAnimation
                  ? this.props.maxPercentage
                  : '0%'
              }
            }}
          />
        </div>
        <div style={{ position: 'relative' }}>
          <h4 style={{ ...styles.progressText, ...styles.progressTextLeft }}>
            {this.props.property}
          </h4>
          <h4 style={{ ...styles.progressText, ...styles.progressTextRight }}>
            {this.props.maxPercentage}
          </h4>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    paddingTop: 10,
    paddingBottom: 30,
    position: 'relative'
  },
  progressWrapper: {
    position: 'relative',
    width: '100%',
    height: '1.5vh',
    borderRadius: 10,
    backgroundColor: '#272727'
  },
  progress: {
    height: '100%',
    maxWidth: '100%',
    borderRadius: 10,
    backgroundColor: '#fd7e14',
    transition: '2.5s'
  },
  progressText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#fff',
    margin: '10px 0 0 0'
  },
  progressTextLeft: {
    float: 'left',
    textAlign: 'left'
  },
  progressTextRight: {
    float: 'right',
    textAlign: 'right'
  }
};

export default ProgressBar;
