import React from 'react';

class FiltersMenu extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      selected: [true] // Selection indicators array
    };
  }

  render() {
    const { filters, onFilterSelected } = this.props;

    return (
      <div style={styles.filtersWrapper}>
        <ul style={{ textAlign: 'center', paddingLeft: 0 }}>
          {filters.map((filter, index) => {
            return (
              <li
                key={`${index}`}
                style={styles.filterElement}
                onClick={() => {
                  let selected = [];
                  selected[index] = true;
                  this.setState({ selected: selected });
                  if (onFilterSelected) onFilterSelected(filter);
                }}
              >
                <h4
                  style={{
                    ...styles.text,
                    ...{
                      color: this.state.selected[index] ? '#fff' : '#d0d0d0'
                    }
                  }}
                >
                  {filter}
                </h4>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

const styles = {
  filtersWrapper: {
    paddingTop: 25,
    paddingBottom: 25
  },
  filterElement: {
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 15px'
  },
  text: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    textAlign: 'center'
  }
};

export default FiltersMenu;
