import React from "react";
import Card from "../../../components/common/card/Card";
import SectionContent from "../../../components/common/section-content/SectionContent";

class TestimonalsBox extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      hovered: false // Indicates whether the component is hovered or not
    };
  }

  render() {
    const { testimonal } = this.props;

    return (
      <Card cardContainerStyle={{ height: "auto" }}>
        <SectionContent
          grid={false}
          bodyLayout={[5, 2, 5]}
          sectionBody={[
            () => {},
            () => {
              return (
                <div style={{ textAlign: "center" }}>
                  <img
                    alt="testimonee"
                    style={styles.img}
                    src={testimonal.thumb}
                  />
                </div>
              );
            },
            () => {}
          ]}
        />
        <SectionContent
          grid={false}
          sectionHeader={{
            content: [
              null,
              testimonal.content[0].text,
              testimonal.content[1].text,
              testimonal.content[2].text,
              testimonal.content[3].text,
              testimonal.content[4].text
            ],
            styles: [
              null,
              testimonal.content[0].style,
              testimonal.content[1].style,
              testimonal.content[2].style,
              testimonal.content[3].style,
              testimonal.content[4].style
            ]
          }}
        />
      </Card>
    );
  }

  static defaultProps = {
    slides: []
  };
}

const styles = {
  img: {
    width: 80,
    height: 56,
    borderRadius: 40,
    backgroundSize: "contain"
  }
};

export default TestimonalsBox;
