import React from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import TextualContent from '../../../../i18n/TextualContent';
import AppNavbarController from './AppNavbarController';
import './AppNavbar.css';

const AppNavbar = props => {
  const { currentSection, sticky } = props;
  let sections = ['header', 'about', 'services', 'portfolio', 'contact'];
  return (
    <div>
      <Navbar
        className={sticky ? 'sticky-navbar' : ''}
        inverse
        collapseOnSelect
        onSelect={event => {
          window.scrollTo({
            top: document.getElementById(sections[event - 1]).offsetTop,
            left: 0,
            behavior: 'smooth'
          });
        }}
      >
        <Navbar.Header>
          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse
          className={
            currentSection !== 'header'
              ? 'navbar-collapse-sticky'
              : 'navbar-collapse-normal'
          }
        >
          <Nav>
            {sections.map((section, index) => (
              <NavItem
                key={index}
                className={currentSection.includes(section) ? 'active' : ''}
                eventKey={index + 1}
              >
                {TextualContent.navbar[index]}
              </NavItem>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default AppNavbarController(AppNavbar);
