import React from 'react';
import PropTypes from 'prop-types';
import WebDevelopment from '../../../assets/icons/web-development.png';
import MobileDevelopment from '../../../assets/icons/mobile-development.png';
import Call from '../../../assets/icons/call.png';
import Location from '../../../assets/icons/location.png';
import Facebook from '../../../assets/icons/facebook.png';
import Linkedin from '../../../assets/icons/linkedin.png';
import Bitbucket from '../../../assets/icons/bitbucket.png';
import Upwork from '../../../assets/icons/upwork.png';
import Email from '../../../assets/icons/email.png';
import Link from '../../../assets/icons/link.png';
import IOS from '../../../assets/icons/ios.png';
import Android from '../../../assets/icons/android.png';

export const iconMap = {
  WebDevelopment: WebDevelopment,
  MobileDevelopment: MobileDevelopment,
  Call: Call,
  Location: Location,
  Facebook: Facebook,
  Linkedin: Linkedin,
  Bitbucket: Bitbucket,
  Upwork: Upwork,
  Email: Email,
  Link: Link,
  IOS: IOS,
  Android: Android
};

class Icon extends React.PureComponent {
  render() {
    const { name, className, style } = this.props;
    const image = iconMap[name];

    return <img alt="icon" className={className} src={image} style={style} />;
  }
}

Icon.displayName = 'Portfolio.Icon';

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object
};

Icon.defaultProps = {
  className: '',
  name: '',
  style: {}
};

export default Icon;
