import React from 'react';
import HeaderParticles from './particles/HeaderParticles';
import HeaderLeft from './header-left/HeaderLeft';
import HeaderRight from './header-right/HeaderRight';
import MyHead from './my-head/MyHead';
import HeaderController from './HeaderController';
import './Header.css';

const Header = props => {
  return (
    <div id="header" style={{ height: props.screenHeight }}>
      <HeaderParticles />
      <HeaderLeft />
      <HeaderRight />
      <MyHead />
    </div>
  );
};

export default HeaderController(Header);
