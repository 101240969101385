import React from 'react';
import AppHeader from '../app-header/AppHeader';
import AppContent from '../app-content/AppContent';
import AppFooter from '../app-footer/AppFooter';
import './App.css';

const App = () => (
  <div>
    <AppHeader />
    <AppContent />
    <AppFooter />
  </div>
);

export default App;
