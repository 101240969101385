import React from 'react';
import Animetable from '../../../common/animetable/Animetable';

const AppLoading = props => {
  let barTimings = ['1s', '1,5s', '2s'];
  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={styles.innerWrapper}>
          {barTimings.map((time, index) => (
            <Animetable
              key={index}
              animationKeyframes={`
                from {width: 0;}
                to {width: 100%;}
              `}
              animationProps={`${time} infinite alternate`}
            >
              <div style={styles.bar} />
            </Animetable>
          ))}

          <Animetable
            animationKeyframes={`
            from {opacity: 0;}
            to {opacity: 1;}
        `}
            animationProps={' 0.5s infinite alternate '}
          >
            <h3 style={styles.text}>LOADING</h3>
          </Animetable>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: window.innerHeight,
    display: 'table'
  },
  wrapper: {
    textAlign: 'center',
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  innerWrapper: {
    display: 'inline-block'
  },
  bar: {
    width: '100%',
    height: 15,
    backgroundColor: 'white',
    marginBottom: 7.5
  },
  text: {
    margin: 0,
    textAlign: 'center',
    fontWeight: '1',
    color: 'white',
    letterSpacing: 2
  }
};

export default AppLoading;
