import React from 'react';

const HeaderController = WrappedComponent => {
  return class HeaderController extends React.Component {
    constructor(props) {
      super();

      // Component level state variables
      this.state = {
        screenHeight: window.outerHeight - 80 // Broweser window height (desktop initially),
      };
      this.flipable = false;
    }

    componentDidMount() {
      window.addEventListener('resize', this.trackResizing);
      this.trackResizing();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.trackResizing);
    }

    trackResizing = () => {
      var self = this;
      let screenHeight = 0;
      switch (window.orientation) {
        case 90:
        case -90:
          screenHeight = Math.min(window.outerHeight, window.outerWidth) - 80;
          self.flipable = true;
          break;
        default:
          if (self.flipable) {
            screenHeight = Math.max(window.outerHeight, window.outerWidth) - 80;
          } else {
            screenHeight =
              Math.min(window.outerHeight, window.innerHeight) - 80;
          }
      }
      if (this.state.screenHeight !== screenHeight)
        self.setState({ screenHeight: screenHeight });
    };

    render() {
      const newProps = {
        screenHeight: this.state.screenHeight
      };
      return <WrappedComponent {...this.props} {...newProps} />;
    }
  };
};

export default HeaderController;
