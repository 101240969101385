import React from 'react';
import SectionContent from '../../../components/common/section-content/SectionContent';
import Card from '../../../components/common/card/Card';
import InputBox from '../../../components/common/input-box/InputBox';
import CustomButton from '../../../components/common/custom-button/CustomButton';
import TextualContent from '../../../i18n/TextualContent';
import ContactInfo from './contact-info/ContactInfo';

class ContactCard extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      name: '',
      nameError: false,
      email: '',
      emailError: false,
      message: '',
      messageError: false
    };

    this.sendEmail = this.sendEmail.bind(this);
  }

  sendEmail() {
    let isInvalidName = this.state.name.length === 0;
    let isInvalidMessage = this.state.message.length === 0;

    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isInvalidEmail = !regex.test(this.state.email);

    if (isInvalidName || isInvalidMessage || isInvalidEmail) {
      console.log('obaaa');
      this.setState({
        nameError: isInvalidName,
        emailError: isInvalidEmail,
        messageError: isInvalidMessage
      });
      return;
    }
    window.open(
      'mailto:' +
        this.state.email +
        '?body=' +
        this.state.message +
        '%0D%0A%0D%0A%0D%0AFrom: ' +
        this.state.name,
      '_self'
    );
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <SectionContent
          bodyLayout={[12]}
          gridStyle={{ height: '100%' }}
          rowColStyle={{ height: '100%' }}
          sectionBody={[
            () => {
              return (
                <Card
                  id="contactCardLayout"
                  cardContainerStyle={{
                    height: '100%',
                    backgroundColor: '#2B2B2B',
                    borderRadius: 12,
                    boxShadow: '0px 0px 5px 0px rgba(255, 255, 255, 0.5)'
                  }}
                >
                  <div
                    id="contactCardContainer"
                    style={{
                      height: '100%',
                      padding: '20px 40px'
                    }}
                  >
                    <SectionContent
                      bodyLayout={[7, 5]}
                      gridStyle={{ height: '100%', width: '100%' }}
                      rowColStyle={{ height: '100%' }}
                      sectionBody={[
                        () => {
                          return (
                            <form
                              id="contactCardForm"
                              style={{
                                padding: '0px 40px',
                                borderRight: '1px solid rgb(0, 0, 0, 0.1)'
                              }}
                            >
                              <h1 style={TextualContent.contactMe.style}>
                                {TextualContent.contactMe.text}
                              </h1>
                              <InputBox
                                error={this.state.nameError}
                                forwardToForm={name =>
                                  this.setState({ name: name })
                                }
                                label={TextualContent.namePlaceHolder.text}
                              />
                              <InputBox
                                error={this.state.emailError}
                                forwardToForm={email =>
                                  this.setState({ email: email })
                                }
                                label={TextualContent.emailPlaceHolder.text}
                              />
                              <InputBox
                                error={this.state.messageError}
                                forwardToForm={message =>
                                  this.setState({ message: message })
                                }
                                inputArea
                                label={TextualContent.messagePlaceHolder.text}
                              />
                              <CustomButton
                                style={{
                                  backgroundColor: '#313335',
                                  color: '#fff'
                                }}
                                hoverAnimation={{
                                  color: '#313335',
                                  backgroundColor: '#fff'
                                }}
                                onPress={() => this.sendEmail()}
                              >
                                {TextualContent.contactSendButton.text}
                              </CustomButton>
                            </form>
                          );
                        },
                        () => {
                          return (
                            <div style={{ paddingTop: 20 }}>
                              <ContactInfo
                                thumb="Location"
                                label={
                                  TextualContent.contactLocationHeader.text
                                }
                              >
                                {TextualContent.contactLocationBody.text}
                              </ContactInfo>
                              <ContactInfo
                                thumb="Call"
                                label={
                                  TextualContent.contactPhoneNumberHeader.text
                                }
                              >
                                {TextualContent.contactPhoneNumberBody.text}
                              </ContactInfo>
                              <ContactInfo
                                thumb="Email"
                                label={TextualContent.contactEmailHeader.text}
                              >
                                {TextualContent.contactEmailBody.text}
                              </ContactInfo>
                            </div>
                          );
                        }
                      ]}
                    />
                  </div>
                </Card>
              );
            }
          ]}
        />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    width: '100%'
  }
};

export default ContactCard;
