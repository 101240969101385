import React from 'react';
import Section from '../../common/section/Section';
import About from '../../../sections/about/About';
import AboutParallax from '../../../sections/about-parallax/AboutParallax';
import Services from '../../../sections/services/Services';
import ServicesParallax from '../../../sections/services-parallax/ServicesParallax';
import Portfolio from '../../../sections/portfolio/Portfolio';
import Contact from '../../../sections/contact/Contact';

const AppContent = () => (
  <div>
    <Section id="about">
      <About />
    </Section>

    <Section parallax id="aboutParallax">
      <AboutParallax />
    </Section>

    <Section id="services">
      <Services />
    </Section>

    <Section parallax id="servicesParallax">
      <ServicesParallax />
    </Section>

    <Section bright id="portfolio">
      <Portfolio />
    </Section>

    <Section id="contact">
      <Contact />
    </Section>
  </div>
);

export default AppContent;
