import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from './section-header/SectionHeader';
import SectionBody from './section-body/SectionBody';
import { Grid, Row, Col } from 'react-bootstrap';

const SectionContent = props => {
  const {
    justifyContentRow,
    grid,
    gridStyle,
    rowColStyle,
    bodyLayout,
    sectionHeader,
    sectionBody
  } = props;

  let content = () => {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <SectionHeader {...sectionHeader} />
          </Col>
        </Row>
        <Row
          style={rowColStyle}
          className={justifyContentRow ? 'justify-content-between' : ''}
        >
          <SectionBody
            bodyLayout={bodyLayout}
            colStyle={rowColStyle}
            sectionBody={sectionBody}
          />
        </Row>
      </div>
    );
  };
  return grid ? <Grid style={gridStyle}>{content()}</Grid> : content();
};

SectionContent.displayName = 'Portfolio.SectionContent';

SectionContent.propTypes = {
  bodyLayout: PropTypes.array,
  sectionBody: PropTypes.array,
  gridStyle: PropTypes.object,
  rowColStyle: PropTypes.object
};

SectionContent.defaultProps = {
  justifyContentRow: false,
  grid: true,
  gridStyle: {},
  rowColStyle: {},
  bodyLayout: [12],
  sectionHeader: () => {},
  sectionBody: []
};

export default SectionContent;
