import React from 'react';
import InViewWrapper from '../../components/common/in-view-wrapper/InViewWrapper';
import Animetable from '../../components/common/animetable/Animetable';
import Parallax from '../../components/common/parallax/Parallax';
import TextualContent from '../../i18n/TextualContent';
import logoImage from '../../assets/images/parallax0/parallax01.png';
import aboutParallaxImage from '../../assets/images/parallax0/parallax00.jpg';

const AboutParallax = () => {
  return (
    <InViewWrapper>
      {({ isInView }) => {
        if (!isInView) return null;
        return (
          <Parallax
            id="parallax0"
            layers={[
              null,
              {
                content: () => {
                  return (
                    <div>
                      <Animetable
                        animationKeyframes={`
                          from {opacity: 0;}
                          to {opacity: 1;}
                          from {transform: scale(0, 0);}
                          to {transform: scale(1, 1);}
                        `}
                        animationProps={'3s'}
                      >
                        <img
                          alt="My dummy logo"
                          id="parallax0Img"
                          src={logoImage}
                          style={styles.parallax0Img}
                        />
                      </Animetable>

                      <div id="boldTextWrapper" style={{ marginBottom: 80 }}>
                        <h1 style={TextualContent.aboutParallaxName.style}>
                          {TextualContent.aboutParallaxName.text}
                        </h1>
                        <h3 style={TextualContent.aboutParallaxJob.style}>
                          {TextualContent.aboutParallaxJob.text}
                        </h3>
                      </div>

                      <h3
                        style={TextualContent.aboutParallaxContentBody1.style}
                      >
                        {TextualContent.aboutParallaxContentBody1.text}
                      </h3>

                      <h3
                        style={TextualContent.aboutParallaxContentBody2.style}
                      >
                        {TextualContent.aboutParallaxContentBody2.text}
                      </h3>
                    </div>
                  );
                }
              }
            ]}
            layersImages={[aboutParallaxImage]}
          />
        );
      }}
    </InViewWrapper>
  );
};

const styles = {
  parallax0Img: {
    display: 'block',
    width: '20%',
    margin: '0 auto'
  }
};

export default AboutParallax;
