import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Icon from '../../../../components/common/icon/Icon';

const ContactInfo = props => {
  const {
    thumb,
    thumbStyle,
    thumbWrapperStyle,
    infoWrapperStyle,
    label,
    children
  } = props;
  return (
    <div>
      <Grid style={{ width: '100%' }}>
        <Row>
          <Col lg={2} md={2} sm={2}>
            <div
              className="info-icon-wrapper"
              style={{ ...styles.thumbWrapperStyle, ...thumbWrapperStyle }}
            >
              <Icon
                className="info-icon"
                name={thumb}
                style={{ ...styles.thumb, ...thumbStyle }}
              />
            </div>
          </Col>
          <Col lg={10} md={10} sm={10} style={{ height: '100%' }}>
            <div
              className="info-wrapper"
              style={{ ...styles.infoWrapper, ...infoWrapperStyle }}
            >
              <p
                className="info"
                style={{
                  fontSize: '1.3em',
                  color: 'rgb(208, 208, 208)',
                  fontWeight: 'bold'
                }}
              >
                {label}
              </p>
              <p
                className="info"
                style={{ fontSize: '1.3em', color: 'rgb(208, 208, 208)' }}
              >
                {children}
              </p>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

ContactInfo.defaultProps = {
  thumb: '',
  thumbStyle: {},
  thumbWrapperStyle: {},
  infoWrapperStyle: {},
  label: '',
  children: ''
};

const styles = {
  thumbWrapperStyle: {
    width: '100%',
    height: '100%',
    padding: '25px 10px'
  },
  thumb: {
    width: 40,
    height: 40,
    padding: 5,
    borderRadius: 20,
    backgroundColor: '#fff',
    verticalAlign: 'middle'
  },
  infoWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px solid rgb(208, 208, 208)'
  }
};

export default ContactInfo;
