import React from "react";

export default {
  navbar: ["Home", "About", "Services", "Portfolio", "Contact"],

  headerHiIam: {
    style: {
      fontSize: "1.7em",
      fontWeight: "bold",
      color: "#878787"
    },
    text: "Hi I'm"
  },

  headerName: {
    style: {
      fontSize: "3.5em",
      fontWeight: "600",
      color: "#fff",
      marginBottom: 20
    },
    text: "Ahmed Mahmoud"
  },

  headerJob: {
    style: {
      fontSize: "2.5em",
      color: "#878787",
      marginBottom: "3rem"
    },
    text: "React & React Native/Expo Developer"
  },

  headerDownloadCv: {
    style: {
      textDecoration: "none",
      color: "inherit",
      fontSize: "inherit",
      fontFamily: "inherit",
      fontWeight: "inherit"
    },
    text: "DOWNLOAD CV"
  },

  headerHireMe: {
    style: {
      textDecoration: "none",
      color: "inherit",
      fontSize: "inherit",
      fontFamily: "inherit",
      fontWeight: "inherit"
    },
    text: "HIRE ME"
  },

  aboutHeader: {
    text: "Who am i",
    style: {
      fontSize: "3em",
      fontWeight: "bold",
      color: "#FFF",
      textAlign: "center",
      marginTop: 0
    }
  },

  aboutUnderHeader: {
    text: "I am the guy for the job",
    style: {
      fontSize: "1.5em",
      fontWeight: "100",
      color: "#d0d0d0",
      textAlign: "center",
      margin: "0"
    }
  },

  aboutContentName: {
    text: "Ahmed Mahmoud",
    style: {
      fontSize: "2.5em",
      fontWeight: "bold",
      color: "#FFF",
      textAlign: "left",
      margin: "0"
    }
  },

  aboutContentJob: {
    text: "React & React Native/Expo Developer",
    style: {
      fontSize: "1.7em",
      fontWeight: "bold",
      color: "#d1d1d1",
      textAlign: "left",
      margin: "0 0 30px 0"
    }
  },

  aboutContentBody1: {
    text:
      "Hello, name is Ahmed, I am a passionate software developer. I can provide you with high quality interactive web apps using React and native mobile apps using React Native for your personal needs or for boasting your business numbers to a whole new level. ",
    style: {
      fontSize: "1.3em",
      fontWeight: "100",
      color: "#d0d0d0",
      textAlign: "left"
    }
  },

  aboutContentBody2: {
    text:
      "I like the work to be neat, clean and organized. Handling pressure is not an issue if everything is in place. That is why I always encourage my team mates to keep things in order to be able to concentrate completely and efficiently on the project challenges",
    style: {
      fontSize: "1.3em",
      fontWeight: "100",
      color: "#d0d0d0",
      textAlign: "left",
      margin: 0
    }
  },

  aboutParallaxName: {
    text: "AHMED MAHMOUD",
    style: {
      fontSize: "2.5em",
      fontWeight: "bold",
      color: "rgb(112, 111, 111)",
      textAlign: "center"
    }
  },

  aboutParallaxJob: {
    text: "AWESOME REACT & REACT NATIVE/EXPO APP BUILDER",
    style: {
      fontSize: "1.7em",
      fontWeight: "100",
      color: "rgb(112, 111, 111)",
      textAlign: "center",
      margin: 0
    }
  },

  aboutParallaxContentBody1: {
    text: (
      <span>
        This is a reactjs single web page sample that services as my portfolio,
        100% modern and responsive.
      </span>
    ),
    style: {
      fontSize: "1.7em",
      fontWeight: "300",
      color: "rgb(112, 111, 111)",
      textAlign: "center",
      margin: 0
    }
  },

  aboutParallaxContentBody2: {
    text: "page builder on the market today.",
    style: {
      fontSize: "1.7em",
      fontWeight: "300",
      color: "rgb(112, 111, 111)",
      textAlign: "center",
      margin: 0
    }
  },

  servicesHeader: {
    text: "What I do",
    style: {
      fontSize: "3em",
      fontWeight: "bold",
      color: "#FFF",
      textAlign: "center",
      marginTop: 0
    }
  },

  servicesUnderHeader1: {
    text: "Whatever the job needs",
    style: {
      fontSize: "1.5em",
      fontWeight: "100",
      color: "#d0d0d0",
      textAlign: "center",
      margin: "0"
    }
  },

  servicesUnderHeader2: {
    text:
      "The skills and technologies mentioned below are of a professional experience",
    style: {
      fontSize: "1.5em",
      fontWeight: "100",
      color: "#d0d0d0",
      textAlign: "center",
      margin: "0"
    }
  },

  servicesContentServices: {
    text: "Services",
    style: {
      fontSize: "2.2em",
      fontWeight: "bold",
      color: "rgb(112, 111, 111)",
      textAlign: "center",
      margin: "0 0 30px 0"
    }
  },

  servicesContentWebDevelopment: {
    text: "Web Development"
  },

  servicesContentMovileDevelopment: {
    text: "Cross Platform"
  },

  servicesContentTechnologies: {
    text: "Technologies",
    style: {
      fontSize: "2.2em",
      fontWeight: "bold",
      color: "rgb(112, 111, 111)",
      textAlign: "center",
      margin: "0 0 30px 0"
    }
  },

  servicesContentReactjs: {
    text: "React"
  },

  servicesContentReactjsPercentage: {
    text: "90%"
  },

  servicesContentHTML: {
    text: "HTML/CSS/JS"
  },

  servicesContentHTMLPercentage: {
    text: "100%"
  },

  servicesContentReactNative: {
    text: "React Native/Expo"
  },

  servicesContentReactNativePercentage: {
    text: "90%"
  },

  servicesContentIonic: {
    text: "Ionic 3 & 4"
  },

  servicesContentIonicPercentage: {
    text: "40%"
  },

  servicesContentLanguages: {
    text: "Languages",
    style: {
      fontSize: "2.2em",
      fontWeight: "bold",
      color: "rgb(112, 111, 111)",
      textAlign: "center",
      margin: "30px 0 30px 0"
    }
  },

  servicesContentJava: {
    text: "Java"
  },

  servicesContentJavaPercentage: {
    text: "90%"
  },

  servicesContentJavaScript: {
    text: "JavaScript"
  },

  servicesContentJavaScriptPercentage: {
    text: "90%"
  },

  servicesParallaxHeader: {
    text: "ADAPTIVITY",
    style: {
      fontSize: "4.5em",
      fontWeight: "bold",
      color: "#272727",
      textAlign: "center"
    }
  },

  servicesParallaxContent: {
    text:
      "In addition to the skills mentioned, I am quite flexible and quick with learning new technologies necessary to complete the handed tasks. My ability to surf the development community out there and extract the required knowledge allows this certin degree of adaptivity in my work style.",
    style: {
      fontSize: "1.4em",
      fontWeight: "100",
      color: "#272727",
      textAlign: "center",
      lineHeight: "40px"
    }
  },

  servicesParallaxHireMe: {
    style: {
      textDecoration: "none",
      color: "inherit",
      fontSize: "inherit",
      fontFamily: "inherit",
      fontWeight: "inherit"
    },
    text: "HIRE ME"
  },

  portfolioHeader: {
    text: "My Wonderful Work",
    style: {
      fontSize: "3em",
      fontWeight: "bold",
      color: "#FFF",
      textAlign: "center",
      marginTop: 0
    }
  },

  portfolioUnderHeader1: {
    text: "Proudly presents the big projects that I have done so far",
    style: {
      fontSize: "1.5em",
      fontWeight: "100",
      color: "#d0d0d0",
      textAlign: "center",
      margin: "0"
    }
  },

  portfolioUnderHeader2: {
    text: "Link is provided upon hovering on each project",
    style: {
      fontSize: "1.5em",
      fontWeight: "100",
      color: "#d0d0d0",
      textAlign: "center",
      margin: "0"
    }
  },

  portfolioContentMyPortfolio: {
    text: "My Portfolio"
  },

  portfolioContentWooAppTemplate: {
    text: "WooAppTemplate"
  },

  portfolioContentQloak: {
    text: "Qloak"
  },

  portfolioContentWeavr: {
    text: "Weavr"
  },

  contactHeader: {
    text: "What People Say",
    style: {
      fontSize: "3em",
      fontWeight: "bold",
      color: "#FFF",
      textAlign: "center",
      margin: "0 0 40px 0"
    }
  },

  contactMe: {
    text: "Contact Me",
    style: {
      fontSize: "3em",
      fontWeight: "bold",
      color: "#FFF",
      textAlign: "center",
      margin: "0 0 20px 0"
    }
  },

  namePlaceHolder: {
    text: "Name"
  },

  emailPlaceHolder: {
    text: "Email"
  },

  messagePlaceHolder: {
    text: "Message"
  },

  contactSendButton: {
    text: "Send Message"
  },

  contactLocationHeader: {
    text: "Location"
  },

  contactLocationBody: {
    text: "Hochschulstr. 46, Dresden Germany"
  },

  contactPhoneNumberHeader: {
    text: "Phone Number"
  },

  contactPhoneNumberBody: {
    text: "+49 1749685148"
  },

  contactEmailHeader: {
    text: "Email"
  },

  contactEmailBody: {
    text: "a.samyabdelhay@gmail.com"
  },

  testimonal: [
    {
      text:
        "Ahmed is an exceptional developer and single-handedly turned our app idea into a reality. He is extremely professional, works hard, gives constant updates without any prompt. He took care of the project like his own and went above and beyond to get it completed. In spite of changing requirements, modifications, he never hesitated or created any fuss and got it done. He is one gem of a developer who can take your idea and turn it into a reality.",
      style: {
        fontSize: "0.8em",
        fontWeight: "100",
        color: "#d0d0d0",
        textAlign: "center",
        margin: "20px 0 5px 0",
        padding: "0 60px"
      }
    },

    {
      text:
        "I will definitely be working with him in future for other development projects!",
      style: {
        fontSize: "1.1em",
        fontWeight: "100",
        color: "#d0d0d0",
        textAlign: "center",
        margin: "0 0 30px 0"
      }
    },

    {
      text: "Saurabh Lakshman & Kamal Arora",
      style: {
        fontSize: "1.3em",
        fontWeight: "bold",
        color: "#d0d0d0",
        textAlign: "center",
        margin: "0 0 10px 0"
      }
    },

    {
      text: "Founders of Qloak",
      style: {
        fontSize: "1.1em",
        fontWeight: "100",
        color: "#d0d0d0",
        textAlign: "center",
        margin: "0"
      }
    },
    {
      text: "saurabh_l@live.com",
      style: {
        fontSize: "1.1em",
        fontWeight: "100",
        color: "#d0d0d0",
        textAlign: "center",
        margin: "0"
      }
    }
  ],

  copywrites: {
    text: "Copyright © All rights reserved."
  }
};
