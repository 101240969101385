import React from 'react';
import PropTypes from 'prop-types';

class Button3D extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      hovered: false // Indicates whether the component is hovered or not
    };
  }

  render() {
    const { buttonStyle, buttonTextStyle, children } = this.props;

    return (
      <button
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        style={{
          ...styles.button,
          ...{ backgroundColor: this.state.hovered ? '#068FFF' : '#069FFF' },
          ...{
            boxShadow: `0 0.5em 0 0 rgb(0, 102, 204, ${
              this.state.hovered ? '1' : '0.8'
            }), 0 10px 19px rgba(0,0,0,0.75)`
          },
          ...buttonStyle
        }}
      >
        <h3 style={{ ...styles.buttonText, ...buttonTextStyle }}>{children}</h3>
      </button>
    );
  }
}

Button3D.displayName = 'Portfolio.Button3D';

Button3D.propTypes = {
  buttonStyle: PropTypes.object,
  buttonTextStyle: PropTypes.object,
  children: PropTypes.node
};

Button3D.defaultProps = {
  buttonStyle: {},
  buttonTextStyle: {},
  children: {}
};

const styles = {
  button: {
    outline: 'none',
    transition: '0.5s',
    display: 'block',
    margin: 0,
    padding: 0,
    width: 280,
    height: 75,
    position: 'relative',
    borderRadius: 4,
    borderColor: 'rgb(0, 102, 204, 1)',
    textShadow: '0 0.2em 0.1em rgba(0,0,0,0.5)'
  },
  buttonText: {
    fontSize: '2em',
    fontWeight: '300',
    letterSpacing: 2,
    margin: 0,
    textAlign: 'center',
    color: '#fff'
  }
};

export default Button3D;
