import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import CustomButton from '../../components/common/custom-button/CustomButton';
import TextualContent from '../../i18n/TextualContent';
import Icon from '../../components/common/icon/Icon';

class Footer extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      mapHeight: 0, // Map iframe height
      underMapAreaHeight: 0
    };
  }

  componentDidMount() {
    this.setState({
      mapHeight: window.innerHeight * 0.75,
      underMapAreaHeight: window.innerHeight * 0.15
    });
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <iframe
          id="mapFrame"
          title="My Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d627.2813908746402!2d13.731161199998697!3d51.03230160000258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709c5f2a5e3adbf%3A0xfefd39d784aefe6f!2sStudenten-Wohnheim+(Hochschulstra%C3%9Fe+46)!5e0!3m2!1sen!2sde!4v1542829753993"
          width="100%"
          height={this.state.mapHeight}
          frameBorder="0"
          style={{ border: 0, marginTop: 250 }}
          allowFullScreen
        />
        <Grid style={{ height: this.state.underMapAreaHeight }}>
          <Row style={{ height: '100%' }}>
            <Col lg={4} md={4} sm={4} style={{ height: '100%' }}>
              <div style={styles.footerArea}>
                <div style={styles.wrapper}>
                  <p style={styles.text}>{TextualContent.copywrites.text}</p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} />
            <Col lg={3} md={3} sm={3} style={{ height: '100%' }}>
              <div style={styles.footerArea}>
                <div id="wrapper" style={styles.wrapper}>
                  <div id="innerWrapper" style={styles.innerWrapper}>
                    <CustomButton
                      small
                      overlayAnimation
                      style={{ backgroundColor: 'transparent' }}
                      wrapperStyle={{ float: 'left', marginRight: 15 }}
                      underlayStyle={{ border: '1px solid #033D83' }}
                      overlayStyle={{ backgroundColor: '#000' }}
                    >
                      <a
                        href="https://www.facebook.com/ahmed.samy.948"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="Facebook" style={styles.icon} />
                      </a>
                    </CustomButton>
                    <CustomButton
                      small
                      overlayAnimation
                      style={{ backgroundColor: 'transparent' }}
                      wrapperStyle={{ float: 'left', marginRight: 15 }}
                      underlayStyle={{ border: '1px solid #1769ff' }}
                      overlayStyle={{ backgroundColor: '#000' }}
                    >
                      <a
                        href="https://www.linkedin.com/in/ahmed-samy-7282b768/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="Linkedin" style={styles.linkedinIcon} />
                      </a>
                    </CustomButton>
                    <CustomButton
                      small
                      overlayAnimation
                      style={{ backgroundColor: 'transparent' }}
                      wrapperStyle={{ float: 'left' }}
                      underlayStyle={{ border: '1px solid #70DA45' }}
                      overlayStyle={{ backgroundColor: '#000' }}
                    >
                      <a
                        href="https://www.upwork.com/o/profiles/users/_~017a2f8c5150c6af8f/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="Upwork" style={styles.icon} />
                      </a>
                    </CustomButton>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
const styles = {
  footerArea: {
    height: '100%',
    width: '100%',
    display: 'table'
  },
  wrapper: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  innerWrapper: {
    display: 'table',
    marginLeft: 'auto'
  },
  icon: {
    width: 15,
    height: 15
  },
  linkedinIcon: {
    width: 10,
    height: 10
  },
  text: {
    fontSize: '1.3em',
    fontWeight: '100',
    color: '#fff',
    textAlign: 'center'
  }
};

export default Footer;
