import React from 'react';
import PropTypes from 'prop-types';

const Section = props => {
  const { id, parallax, bright, children } = props;

  const parallaxStyle = parallax ? styles.parallax : {};
  const brightStyle = bright ? styles.bright : {};

  return (
    <section
      style={{ ...styles.section, ...parallaxStyle, ...brightStyle }}
      id={id}
    >
      {children}
    </section>
  );
};

Section.displayName = 'Portfolio.Section';

Section.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
};

Section.defaultProps = {
  id: '',
  parallax: false,
  bright: false,
  children: ''
};

const styles = {
  section: {
    width: '100%',
    paddingTop: '5%',
    paddingBottom: '5%',
    position: 'relative'
  },
  parallax: {
    padding: 0
  },
  bright: {
    backgroundColor: 'rgb(43, 43, 43)'
  }
};

export default Section;
