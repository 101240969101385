import React from 'react';
import PropTypes from 'prop-types';
import Underlay from './underlay/Underlay';
import Button from './button/Button';
import Overlay from './overlay/Overlay';

class CustomButton extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      hovered: false // Hovering event state
    };
  }

  render() {
    const { id, overlayAnimation, wrapperStyle } = this.props;

    return (
      <div id={id} style={{ ...styles.wrapper, ...wrapperStyle }}>
        {overlayAnimation && (
          <Underlay hovered={this.state.hovered} {...this.props} />
        )}
        <Button
          hovered={this.state.hovered}
          onHover={() => this.setState({ hovered: true })}
          onLeave={() => this.setState({ hovered: false })}
          {...this.props}
        />
        {overlayAnimation && (
          <Overlay hovered={this.state.hovered} {...this.props} />
        )}
      </div>
    );
  }
}

CustomButton.displayName = 'Portfolio.Button';

CustomButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  hoverAnimation: PropTypes.object,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
  underlayStyle: PropTypes.object,
  overlayStyle: PropTypes.object,
  children: PropTypes.node
};

CustomButton.defaultProps = {
  id: '',
  type: 'button',
  onPress: () => {},
  small: false,
  overlayAnimation: false,
  hoverAnimation: {},
  children: {},
  style: {},
  wrapperStyle: {},
  underlayStyle: {},
  overlayStyle: {}
};

const styles = {
  wrapper: {
    position: 'relative',
    display: 'table'
  }
};

export default CustomButton;
