import React from 'react';
import SectionContent from '../../components/common/section-content/SectionContent';
import InViewWrapper from '../../components/common/in-view-wrapper/InViewWrapper';
import Animetable from '../../components/common/animetable/Animetable';
import Icon from '../../components/common/icon/Icon';
import ServicesBox from './services-box/ServicesBox';
import ProgressBar from './progress-bar/ProgressBar';
import TextualContent from '../../i18n/TextualContent';
import SkillsData from './SkillsData';

const generateSkillsBars = progressBars => {
  return progressBars.map((progressBar, index) => (
    <div key={index} style={{ padding: '10px 0' }}>
      <ProgressBar
        id={progressBar}
        property={SkillsData[progressBar].skill}
        maxPercentage={SkillsData[progressBar].maxPercentage}
      />
    </div>
  ));
};

const Services = () => (
  <SectionContent
    justifyContentRow
    bodyLayout={[6, 6]}
    sectionHeader={{
      content: [
        TextualContent.servicesHeader.text,
        TextualContent.servicesUnderHeader1.text,
        TextualContent.servicesUnderHeader2.text
      ],
      styles: [
        TextualContent.servicesHeader.style,
        TextualContent.servicesUnderHeader1.style,
        TextualContent.servicesUnderHeader2.style
      ],
      wrapperStyle: styles.headerWrapper
    }}
    sectionBody={[
      () => {
        return (
          <InViewWrapper>
            {({ isInView }) => {
              if (!isInView) return null;
              return (
                <Animetable
                  animationKeyframes={`
                    0% {opacity: 0;}
                    20% {opacity: 1;}
                    40% {opacity: 0;}
                    60% {opacity: 1;}
                    80% {opacity: 0;}
                    100% {opacity: 1;}
                  `}
                  animationProps={'3s'}
                >
                  <div style={styles.fadeInSlideAnimation} id="servicesBox">
                    <SectionContent
                      bodyLayout={[6, 6]}
                      grid={false}
                      sectionHeader={{
                        content: [
                          null,
                          TextualContent.servicesContentServices.text
                        ],
                        styles: [
                          null,
                          TextualContent.servicesContentServices.style
                        ]
                      }}
                      sectionBody={[
                        () => {
                          return (
                            <div style={{ padding: '30px 0' }}>
                              <ServicesBox icon={'WebDevelopment'}>
                                {
                                  TextualContent.servicesContentWebDevelopment
                                    .text
                                }
                              </ServicesBox>
                            </div>
                          );
                        },
                        () => {
                          return (
                            <div style={{ padding: '30px 0' }}>
                              <ServicesBox icon={'MobileDevelopment'}>
                                {
                                  TextualContent
                                    .servicesContentMovileDevelopment.text
                                }
                              </ServicesBox>
                            </div>
                          );
                        }
                      ]}
                    />
                  </div>
                </Animetable>
              );
            }}
          </InViewWrapper>
        );
      },
      () => {
        return (
          <InViewWrapper>
            {({ isInView }) => {
              if (!isInView) return null;
              return (
                <Animetable
                  animationKeyframes={`
                    0% {opacity: 0;}
                    20% {opacity: 1;}
                    40% {opacity: 0;}
                    60% {opacity: 1;}
                    80% {opacity: 0;}
                    100% {opacity: 1;}
                `}
                  animationProps={'3s'}
                >
                  <div style={styles.fadeInSlideAnimation} id="skillsBox">
                    <SectionContent
                      grid={false}
                      bodyLayout={[6, 6]}
                      sectionHeader={{
                        content: [
                          null,
                          TextualContent.servicesContentTechnologies.text
                        ],
                        styles: [
                          null,
                          TextualContent.servicesContentTechnologies.style
                        ]
                      }}
                      sectionBody={[
                        () => {
                          return (
                            <div>
                              <div style={styles.iconWrapper}>
                                <Icon
                                  name={'WebDevelopment'}
                                  style={styles.icon}
                                />
                              </div>
                              {generateSkillsBars(['progress1', 'progress2'])}
                            </div>
                          );
                        },
                        () => {
                          return (
                            <div>
                              <div style={styles.iconWrapper}>
                                <Icon
                                  name={'MobileDevelopment'}
                                  style={styles.icon}
                                />
                              </div>
                              {generateSkillsBars(['progress3', 'progress4'])}
                            </div>
                          );
                        }
                      ]}
                    />
                    <SectionContent
                      grid={false}
                      bodyLayout={[12]}
                      sectionBody={[
                        () => {
                          return (
                            <div>
                              <h3
                                style={
                                  TextualContent.servicesContentLanguages.style
                                }
                              >
                                {TextualContent.servicesContentLanguages.text}
                              </h3>
                              {generateSkillsBars(['progress5', 'progress6'])}
                            </div>
                          );
                        }
                      ]}
                    />
                  </div>
                </Animetable>
              );
            }}
          </InViewWrapper>
        );
      }
    ]}
  />
);

const styles = {
  fadeInSlideAnimation: {
    width: '100%',
    height: '100%'
  },
  headerWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 80
  },
  iconWrapper: {
    width: '100%',
    textAlign: 'center'
  },
  icon: {
    width: 50,
    height: 50
  }
};

export default Services;
