import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = props => {
  const { id, content, styles, wrapperStyle } = props;

  return (
    <div id={id} style={wrapperStyle}>
      {content[0] && <h1 style={styles[0]}>{content[0]}</h1>}
      {content.map((text, index) => {
        if (index > 0)
          return (
            <p key={index} style={styles[index]}>
              {text}
            </p>
          );
        else return null;
      })}
    </div>
  );
};

SectionHeader.displayName = 'Portfolio.SectionHeader';

SectionHeader.propTypes = {
  id: PropTypes.string,
  content: PropTypes.array,
  styles: PropTypes.array,
  wrapperStyle: PropTypes.object
};

SectionHeader.defaultProps = {
  id: '',
  content: [],
  styles: [],
  wrapperStyle: {}
};

export default SectionHeader;
