import TextualContent from "../../i18n/TextualContent";
import ionicImage from "../../assets/images/portfolio-section/ionic-app.jpg";
import reactjsImage from "../../assets/images/portfolio-section/reactjs-app.png";
import reactNativeImage from "../../assets/images/portfolio-section/react-native-app.jpg";
import weavrImage from "../../assets/images/portfolio-section/weavr.png";

const PortfolioProjectsData = [
  {
    name: TextualContent.portfolioContentMyPortfolio.text,
    platform: "REACTJS",
    thumb: reactjsImage,
    repoLink: "https://bitbucket.org/ahmedsamyabdelhay/portfolio/src/master/",
    link: "www.ahmedmahmoud.de"
  },
  {
    name: TextualContent.portfolioContentQloak.text,
    platform: "REACT NATIVE/EXPO",
    thumb: reactNativeImage,
    //android: 'https://play.google.com/store/apps/details?id=com.qloak.app',
    //ios: "https://itunes.apple.com/us/app/qloak/id1424915231?mt=8"
    link:
      "mailto:saurabh_l@live.com?subject=Confirm%20Role%20of%20Ahmed%20Mahmoud%20as%20A%20Lead%20Developer%20of%20Qloak"
  },
  {
    name: TextualContent.portfolioContentWeavr.text,
    platform: "REACTJS",
    thumb: weavrImage,
    link: "https://weavr.ai"
  }
];

export default PortfolioProjectsData;
