import React from 'react';
import { Button } from 'react-bootstrap';

const ButtonComponent = props => {
  const {
    type,
    onPress,
    onHover,
    onLeave,
    overlayAnimation,
    style,
    small,
    hovered,
    hoverAnimation,
    children
  } = props;

  return (
    <Button
      type={type}
      onClick={onPress}
      onMouseEnter={() => onHover()}
      onMouseLeave={() => onLeave()}
      style={{
        ...styles.button,
        ...(overlayAnimation ? styles.buttonOverlayAnimation : {}),
        ...style,
        ...(small ? styles.small : styles.normal),
        ...(hovered ? hoverAnimation : {})
      }}
    >
      {typeof children === 'string' && (
        <h3 style={styles.buttonText}>{children}</h3>
      )}
      {typeof children !== 'string' && children}
    </Button>
  );
};

const styles = {
  button: {
    fontWeight: 'bold',
    outline: 'none',
    transition: '0.5s',
    border: 0,
    borderRadius: '200px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: '#fff'
  },
  small: {
    width: 40,
    height: 40
  },
  normal: {
    padding: '1rem 2rem'
  },
  buttonText: {
    fontSize: '1em',
    margin: 0
  },
  buttonOverlayAnimation: {
    position: 'absolute',
    top: 0,
    zIndex: 1
  }
};

export default ButtonComponent;
