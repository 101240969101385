import TextualContent from '../../i18n/TextualContent';

const SkillsData = {
  progress1: {
    skill: TextualContent.servicesContentReactjs.text,
    maxPercentage: TextualContent.servicesContentReactjsPercentage.text
  },
  progress2: {
    skill: TextualContent.servicesContentReactNative.text,
    maxPercentage: TextualContent.servicesContentReactNativePercentage.text
  },
  progress3: {
    skill: TextualContent.servicesContentHTML.text,
    maxPercentage: TextualContent.servicesContentHTMLPercentage.text
  },
  progress4: {
    skill: TextualContent.servicesContentIonic.text,
    maxPercentage: TextualContent.servicesContentIonicPercentage.text
  },
  progress5: {
    skill: TextualContent.servicesContentJava.text,
    maxPercentage: TextualContent.servicesContentJavaPercentage.text
  },
  progress6: {
    skill: TextualContent.servicesContentJavaScript.text,
    maxPercentage: TextualContent.servicesContentJavaScriptPercentage.text
  }
};

export default SkillsData;
