import React from 'react';
import Icon from '../../../components/common/icon/Icon';

class PortfolioBox extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      hovered: false, // Indicates whether the component is hovered or not
      hoveredLinks: [false, false, false, false]
    };
  }

  renderBoxButton(index, link, icon, marginRight) {
    return (
      <div
        key={index}
        onMouseEnter={() => {
          let links = this.state.hoveredLinks.slice();
          links[index] = true;
          this.setState({ hoveredLinks: links });
        }}
        onMouseLeave={() => {
          let links = this.state.hoveredLinks.slice();
          links[index] = false;
          this.setState({ hoveredLinks: links });
        }}
        style={{
          ...styles.circularButton,
          ...{
            marginRight: marginRight,
            backgroundColor: this.state.hoveredLinks[index]
              ? 'rgba(0, 0, 0, 0.7)'
              : 'transparent',
            border: this.state.hoveredLinks[index]
              ? '1px solid #000'
              : '1px solid #FFF'
          }
        }}
      >
        <a href={link}>
          <Icon name={icon} style={styles.icon} />
        </a>
      </div>
    );
  }

  render() {
    const { image, name, repoLink, link, android, ios } = this.props;

    let buttons = [
      {
        link: link,
        icon: 'Link',
        marginRight: repoLink || android || ios ? 10 : 0
      },
      {
        link: repoLink,
        icon: 'Bitbucket',
        marginRight: android || ios ? 10 : 0
      },
      {
        link: android,
        icon: 'Android',
        marginRight: ios ? 10 : 0
      },
      {
        link: ios,
        icon: 'IOS',
        marginRight: 0
      }
    ];

    return (
      <div
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        style={styles.wrapper}
      >
        <img alt="project logo" style={styles.img} src={image} />
        <div
          style={{
            ...styles.overlay,
            ...{ top: this.state.hovered ? 0 : '85%' }
          }}
        >
          <div style={styles.headerWrapper}>
            <h3 style={styles.text}>{name}</h3>
          </div>
          <div>
            <div style={styles.linksWrapper}>
              {buttons.map((button, index) => {
                return (
                  button.link &&
                  this.renderBoxButton(
                    index,
                    button.link,
                    button.icon,
                    button.marginRight
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'relative',
    display: 'table',
    overflow: 'hidden',
    margin: '0 auto'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(49, 51, 53, 0.7)',
    transition: '0.5s'
  },
  headerWrapper: {
    height: '40%'
  },
  linksWrapper: {
    display: 'table',
    margin: 'auto'
  },
  circularButton: {
    float: 'left',
    borderRadius: 25,
    padding: 5,
    transition: '0.5s'
  },
  icon: {
    width: 40,
    height: 40
  },
  text: {
    fontSize: '1.4em',
    fontWeight: '300',
    textAlign: 'center',
    color: '#fff',
    margin: '12.5px 0'
  }
};

export default PortfolioBox;
