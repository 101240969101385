import React from 'react';

const Underlay = props => {
  const { underlayStyle, small, hovered, children } = props;

  return (
    <div
      style={{
        ...styles.underlay,
        ...underlayStyle,
        ...(small ? styles.small : styles.normal),
        ...{ opacity: hovered ? 0 : 1 }
      }}
    >
      <div style={{ opacity: 0 }}>
        {typeof children === 'string' && (
          <h3 style={styles.buttonText}>{children}</h3>
        )}
        {typeof children !== 'string' && children}
      </div>
    </div>
  );
};

const styles = {
  underlay: {
    borderRadius: '200px',
    border: '2px solid #fff',
    transition: '1s'
  },
  small: {
    width: 40,
    height: 40
  },
  normal: {
    padding: '1rem 2rem'
  },
  buttonText: {
    fontSize: '1em',
    margin: 0
  }
};

export default Underlay;
