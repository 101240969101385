import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Animetable = props => {
  const { id, animationKeyframes, animationProps, style, children } = props;

  const frames = keyframes`${animationKeyframes}`;

  const AnimatedDiv = styled.div`
    animation: ${frames} ${animationProps};
  `;

  return (
    <AnimatedDiv id={id} style={{ ...style }}>
      {children}
    </AnimatedDiv>
  );
};

Animetable.displayName = 'Portfolio.Animetable';

Animetable.propTypes = {
  keyframes: PropTypes.string,
  animationProps: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
};

Animetable.defaultProps = {
  id: '',
  keyframes: '',
  animationProps: '',
  style: {},
  children: {}
};

export default Animetable;
