import React from 'react';
import Icon from '../../../components/common/icon/Icon';

class ServicesBox extends React.Component {
  constructor(props) {
    super();

    // Component level state variables
    this.state = {
      hovered: false // Indicates whether the component is hovered or not
    };
  }

  render() {
    const { icon, children } = this.props;
    return (
      <div
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        style={styles.wrapper}
      >
        <div
          style={{
            ...styles.underlay,
            ...{ opacity: this.state.hovered ? 0 : 1 }
          }}
        >
          <div style={{ opacity: 0 }}>
            <div
              style={{
                display: 'table-cell',
                verticalAlign: 'middle'
              }}
            >
              <Icon name={icon} style={styles.icon} />
              <h3 style={styles.text}>{children}</h3>
            </div>
          </div>
        </div>
        <div style={styles.box}>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle'
            }}
          >
            <Icon name={icon} style={styles.icon} />
            <h3 style={styles.text}>{children}</h3>
          </div>
        </div>
        <div
          style={{
            ...styles.overlay,
            ...{
              transform: this.state.hovered ? 'scale(1,1)' : 'scale(0,0)'
            }
          }}
        />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '52.5vh'
  },
  underlay: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    border: '1px solid grey',
    transition: '2.5s'
  },
  box: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    borderRadius: 4,
    textAlign: 'center',
    transition: '1s',
    display: 'table'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    zIndex: 0,
    backgroundColor: '#272727',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    borderRadius: 4,
    textAlign: 'center',
    transition: '0.5s',
    display: 'table'
  },
  icon: {
    width: 50,
    height: 50
  },
  text: {
    fontSize: '1.6em',
    fontWeight: '300',
    textAlign: 'center',
    color: '#fff',
    margin: '20px 0 0 0'
  }
};

export default ServicesBox;
