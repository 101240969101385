import React from 'react';
import Waypoint from 'react-waypoint';

class InViewWrapper extends React.Component {
  constructor(props) {
    super(props);

    // Component level state variables
    this.state = {
      isInView: false
    };

    this.onEnter = this.onEnter.bind(this);
  }

  onEnter({ previousPosition }) {
    if (previousPosition === Waypoint.below && !this.state.isInView)
      this.setState({ isInView: true });
  }

  render() {
    return (
      <div>
        <Waypoint onEnter={this.onEnter} />
        {this.props.children({ isInView: this.state.isInView })}
      </div>
    );
  }
}

InViewWrapper.displayName = 'Portfolio.InViewWrapper';

InViewWrapper.defaultProps = {
  children: {}
};

export default InViewWrapper;
